/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// exports.onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//         `В приложении произошли изменения. ` +
//         `Перезагрузить страницу и получить обновления?`
//     );
//
//     if (answer === true) {
//         window.location.reload()
//     }
// };
//
// exports.registerServiceWorker = () => true;

export const onClientEntry = async () => {
  // подключение полифила на IntersectionObserver
  if (typeof IntersectionObserver === `undefined`) {
    await import('intersection-observer');
  }
};

export const onInitialClientRender = () => {
  // eslint-disable-next-line
  const _cssVars = require('css-vars-ponyfill');
  _cssVars.default();
};

export const onPreRouteUpdate = () => {
  // eslint-disable-next-line
  const _cssVars = require('css-vars-ponyfill');
  _cssVars.default();
};
